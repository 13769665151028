.mainView {
    border: 1px solid #DDDDDD !important;
    padding: 30px !important
}

.productDescText {
    color: #000 !important;
    font-family: InterBold !important;
    font-size: 20px !important;
}

.imgView {
    height: 40px !important;
    width: 40px !important;
}

.imgStyle {
    height: 100% !important;
    width: 100% !important;
}

.hsCodeText {
    color: #000 !important;
    font-family: InterMedium !important;
    font-size: 14px !important;
}

.sellerTitleText {
    color: #000 !important;
    font-family: InterMedium !important;
    font-size: 14px !important;
}

.sellerText {
    color: #315CCB !important;
    font-family: InterBold !important;
    font-size: 14px !important;
    text-transform: capitalize !important;
}

.countryText {
    color: #000 !important;
    font-family: InterMedium !important;
    font-size: 14px !important;
}

.quantityText {
    color: #000 !important;
    font-family: InterMedium !important;
    font-size: 14px !important;
}

.totalPriceText {
    color: #000 !important;
    font-family: InterMedium !important;
    font-size: 14px !important;
}
