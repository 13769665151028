.mainLabelText {
    color: #000 !important;
    font-family: 'InterBold' !important;
    font-size: 15px !important;
}

.labelText {
    color: #000 !important;
    font-family: 'InterMedium' !important;
    font-size: 15px !important;
}

.clipped {
    clip-path: inset(0 100% 0 0);
}
