.descBox {
    background-color: #f7f5eb !important;
    padding: 20px !important;
}

.descText {
    font-size: 16px !important;
    font-family: InterMedium !important;
    line-height: 1.5 !important;
}

.mainTitleBox {
    position: relative;
    border: 1px solid #DDDDDD;
}

.titleView {
    position: absolute;
    top: -13px;
    left: 45px;
    background-color: #FFFFFF
}

.titleText {
    color: #000;
    font-family: InterMedium !important;
    font-size: 16px;
}
