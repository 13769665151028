.landingTitleText {
    color: #000;
    font-family: InterBold !important;
    font-size: 28px !important;
    line-height: 26px !important;
}

.landingDescText {
    color: #000 !important;
    font-family: InterMedium !important;
    font-size: 13px !important;
}

.cardView {
    height: 100% !important;
}

.cardBoxView {
    position: relative !important;
}

.cardSubView {
    position: relative !important;
    overflow: hidden !important;
    border-radius: 12px !important;
    height: 190px !important;
}

.boxView {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    border-bottom-right-radius: 6px !important;
    padding: 4px !important;
}

.featureTitleText {
    font-family: InterBold !important;
    font-size: 13px !important;
}

.featureDescText {
    font-family: InterMedium !important;
    font-size: 12px !important;
    text-align: center !important;
}

.numberText {
    color: #fff !important;
    font-family: InterMedium !important;
    font-size: 12px !important;
}

.landingTopLineView {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
}

.bottomLineArrowView {
    display: flex !important;
    align-items: flex-end !important;
    position: absolute !important;
    bottom: -5px!important;
}

.landingArrowView {
    margin-bottom: -14px !important;
    margin-left: -13px !important;
}

.mainBoxView {
    position: relative !important;
    background-color: #FFFFFF !important;
    border: 1px solid #128807 !important;
    overflow: hidden !important;
}

.stepView {
    content: "";
    display: block;
    /* position the triangle in the top left corner */
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    /* create the triangle */
    width: 0;
    height: 0;
    border: 3em solid transparent;
    border-top-color: #D8F0D4;
    border-left-color: #D8F0D4;
}

.stepText {
    color: #000 !important;
    font-family: InterBold !important;
    font-size: 13px !important;
    transform: rotate(-50deg);
    position: absolute !important;
    top: 18px !important;
    left: 10px!important;
}

.stepIconView {
    height: 150px !important;
    width: 150px !important;
}

.stepContentText {
    color: #000 !important;
    font-family: InterBold !important;
    font-size: 14px !important;
}

.stepContentDataText {
    color: #000 !important;
    font-family: InterMedium !important;
    font-size: 13px !important;
}

.borderBottomLine {
    border-bottom: 1px solid #4B9A0D;
    width: 50% !important;
}

.doorRegisterMainView {
    border: 1px solid #fff !important;
    border-radius: 50px !important;
    overflow: hidden !important;
}

.doorStepView {
    background-color: #4B9A0D !important;
    position: relative !important;
}

.doorstepText {
    color: #fff !important;
    font-family: InterBold !important;
    font-size: 30px !important;
}

.freshStartText {
    color: #fff !important;
    font-family: InterBold !important;
    font-size: 30px !important;
}

.doorstepDescText {
    color: #fff !important;
    font-family: InterMedium !important;
    font-size: 14px !important;
}

.registerBusinessView {
    background-color: #89c35a !important;
}

.curveLineView {
    position: absolute !important;
    bottom: 0px !important;
    right: 0px !important;
}
