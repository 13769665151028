.addressMainText {
    color: #000 !important;
    font-family: InterBold !important;
    font-size: 15px !important;
}

/*.billingDetailMainView {*/
/*    border: 1px solid #DDDDDD;*/
/*    padding: 30px*/
/*}*/

.borderBottomLine {
    border-bottom: 1px solid #DDD;
}

.moqErrorText {
    color: red !important;
    font-family: InterBold !important;
    font-size: 12px !important;
}

/*.paymentCartText {*/
/*    color: #000 !important;*/
/*    font-family: InterBold !important;*/
/*    font-size: 18px !important;*/
/*}*/

/*.orderTotalText {*/
/*    color: #000 !important;*/
/*    font-family: InterBold !important;*/
/*    font-size: 15px !important;*/
/*}*/

/*.priceText {*/
/*    color: #000 !important;*/
/*    font-family: InterBold !important;*/
/*    font-size: 15px !important;*/
/*}*/

/*.paymentMethodBox {*/
/*    border: 1px solid #DDDDDD;*/
/*}*/

/*.paymentMethodSubBox {*/
/*    cursor: pointer;*/
/*    padding: 20px*/
/*}*/

/*.paymentMethodText {*/
/*    color: #000 !important;*/
/*    font-family: InterBold !important;*/
/*    font-size: 15px !important;*/
/*}*/

/*.paymentMethodDescText {*/
/*    color: #000 !important;*/
/*    font-family: InterMedium !important;*/
/*    font-size: 15px !important;*/
/*}*/

/*.labTestReportBox {*/
/*    cursor: pointer;*/
/*}*/

/*.labTestReportText {*/
/*    color: #000 !important;*/
/*    font-family: InterSemiBold !important;*/
/*    font-size: 15px !important;*/
/*}*/
