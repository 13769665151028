.borderBottomLine {
    border-bottom: 1px solid #DDD;
}

.dataView {
    width: 20% !important;
}

.btnView {
    cursor: pointer !important;
}

.mainLabelText {
    color: #000 !important;
    font-family: 'InterBold' !important;
    font-size: 15px !important;
}

.labelText {
    color: #000 !important;
    font-family: 'InterBold' !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
}

.subLabelText {
    color: #315CCB !important;
    text-transform: capitalize !important;
    font-size: 12px !important;
    font-family: 'InterMedium' !important;
}

.totalPriceLabelText {
    color: red;
    font-family: InterBold !important;
    font-size: 20px !important;
}

.totalPriceText {
    color: red;
    font-family: InterMedium !important;
    font-size: 16px !important;
}

.sellerTotalPriceText {
    color: red;
    font-family: InterMedium !important;
    font-size: 20px !important;
}

.paymentAmtText {
    color: #000;
    font-family: InterBold !important;
    font-size: 12px !important;
}

.paymentMainDescView {
    background-color: #D8F0D4 !important;
    border: 1px solid #D8F0D4 !important;
}

.paymentMainDescText {
    color: #000;
    font-family: InterMedium !important;
    font-size: 13px !important;
}

.imgView {
    height: 30px;
    width: 30px
}

.imgStyle {
    object-fit: contain;
    height: 100%;
    width: 100%;
}

.dataView {
    width: 20% !important;
}

.productNameTitleText {
    color: #000;
    font-family: InterBold !important;
    font-size: 15px !important;
}

.productNameText {
    color: #000;
    font-family: InterMedium !important;
    font-size: 15px !important;
}

.nameEntityText {
    color: #000;
    font-family: InterMedium !important;
    font-size: 15px !important;
    text-transform: capitalize !important;
}

.hsCodeText {
    color: #000;
    font-family: InterMedium !important;
    font-size: 12px !important;
}

.deliveryAddressText {
    color: #000;
    font-family: InterMedium !important;
    font-size: 13px !important;
}

.shippingDetailText {
    color: #000;
    font-family: InterMedium !important;
    font-size: 13px !important;
}

.orderQuantityText {
    color: #000;
    font-family: InterMedium !important;
    font-size: 12px !important;
}

.priceText {
    color: #000 !important;
    font-family: InterMedium !important;
    font-size: 15px !important;
}

.borderBottomLine {
    border-bottom: 1px solid #DDD;
}

.addToCartView {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border: 1px solid #DDD;
    height: 50px;
}

.pointerView {
    cursor: pointer;
}

.plusMinusNoText {
    color: #000;
    font-family: InterBold !important;
    font-size: 20px;
}

.borderRightLine {
    font-weight: bold;
    border-right: 1px solid #DDD;
    height: 50px;
}

.borderLeftLine {
    font-weight: bold;
    border-left: 1px solid #DDD;
    height: 50px;
}

.subPriceText {
    color: red;
    font-family: InterBold !important;
    font-size: 13px !important;
}

.sellerSubPriceText {
    color: red;
    font-family: InterBold !important;
    font-size: 15px !important;
}

.subPricePerKgText {
    color: red;
    font-family: InterBold !important;
    font-size: 11px !important;
}

.exwText {
    color: #000 !important;
    font-family: InterMedium !important;
    font-size: 11px !important;
}

.sellerSubPricePerKgText {
    color: red;
    font-family: InterBold !important;
    font-size: 11px !important;
}

.calculationPendingText {
    color: #315CCB !important;
    font-family: InterMedium !important;
    font-size: 13px !important;
}

.sellerCalculationPendingText {
    color: #315CCB !important;
    font-family: InterMedium !important;
    font-size: 12px !important;
}

.moqErrorText {
    color: red;
    font-family: InterBold !important;
    font-size: 12px !important;
}

.cartTotalTitleText {
    color: #000;
    font-family: InterBold !important;
    font-size: 16px !important;
}

.paymentTermText {
    color: #000 !important;
    font-family: InterRegular !important;
    font-size: 14px !important
}

.btnView {
    cursor: pointer !important;
}
