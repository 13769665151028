.container {
    border: 1px solid #d3d3d3;
    padding: 40px 0px;
    margin-bottom: 25px;
}

.containerIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.icon {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cardContainer {
    position: relative;
}

.cardIcons {
    position: absolute;
    top: 40%;
    right: 15px;
}

.contactMessageIcon {
    position: absolute;
    top: 15px;
    right: 15px;
}

.mapContainer {
    display: flex;
    position: relative;
}

.boxTitleText {
    font-family: InterBold !important;
    font-size: 20px !important;
}

.boxContentText {
    font-family: InterMedium !important;
    font-size: 15px !important;
}

.quoteTitleText {
    font-family: InterBold !important;
    font-size: 25px !important;
}

.checkboxText {
    font-family: InterMedium !important;
    font-size: 14px !important;
}
