.addressChooseTitleView {
    cursor: pointer!important;
}

.addressChooseTitleText {
    color: #000 !important;
    font-family: InterMedium !important;
    font-size: 15px !important;
}

.addressMainView {
    border: 1px solid #DDDDDD;
    padding: 30px
}
