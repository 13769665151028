@font-face {
    font-family: "InterBold";
    src: url("../fonts/inter/Inter-Bold.ttf");
}

@font-face {
    font-family: "InterSemiBold";
    src: url("../fonts/inter/Inter-SemiBold.ttf");
}

@font-face {
    font-family: "InterMedium";
    src: url("../fonts/inter/Inter-Medium.ttf");
}

@font-face {
    font-family: "InterRegular";
    src: url("../fonts/inter/Inter-Regular.ttf");
}

@font-face {
    font-family: "InterItalic";
    src: url("../fonts/inter/Inter-Italic.otf");
}

@font-face {
    font-family: "InterItalicBold";
    src: url("../fonts/inter/Inter-BoldItalic.otf");
}

body {
    margin: 0 !important;
}

.productList:hover {
    box-shadow: 0 3px 10px rgba(0.1, 0.1, 0.1, 0.1);
}

.productListIcon {
    display: none !important;
}

.productList:hover .productListIcon {
    display: flex !important;
}

.productListEyeIconBox:hover {
    background-color: #80B500 !important;
}

.productListCartIconBox:hover {
    background-color: #80B500 !important;
}

.productListLikeIconBox:hover {
    background-color: #80B500 !important;
}

.productListArrowIcon {
    display: none !important;
}

.relatedProductList:hover .productListArrowIcon {
    display: flex !important;
}

.productListArrowIconBox:hover {
    background-color: #80B500 !important;
}

.headerBar {
    position: relative !important;
}

.headerBarFixed {
    position: fixed !important;
}

.categoryFilterView {
    cursor: pointer;
}

.categoryFilterView:hover .categoryFilterText {
    color: #80B500
}

.headerCartView:hover .headerCartText {
    color: #FFF !important;
}

#prdtImgSlide .slick-slide {
    width: 110px !important;
}

.MuiGrid-root .MuiGrid-container .MuiGrid-item .MuiGrid-grid-xs-12 .MuiGrid-grid-md-12 .css-az7e3u-MuiGrid-root {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.MuiGrid-root .MuiGrid-container .MuiGrid-item .MuiGrid-grid-xs-12 .MuiGrid-grid-md-12 .css-az7e3u-MuiGrid-root .Mui-focused {
    border: 1px solid #80B500 !important;
    height: 55px !important;
}

.MuiGrid-root .MuiGrid-container .MuiGrid-item .MuiGrid-grid-xs-12 .MuiGrid-grid-md-12 .css-yfynr3-MuiGrid-root {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    border-radius: 0 !important;
}

.MuiGrid-root .MuiGrid-container .MuiGrid-item .MuiGrid-grid-xs-12 .MuiGrid-grid-md-12 .css-yfynr3-MuiGrid-root .Mui-focused {
    border: 1px solid #80B500 !important;
    border-radius: 0 !important;
}

/*.MuiGrid-root .MuiGrid-item .MuiGrid-grid-xs-12 .MuiGrid-grid-md-12 .css-1idn90j-MuiGrid-root {*/
/*    height: 25px!important;*/
/*}*/

thead {
    background-color: #D8F0D4 !important;
}

thead .MuiTableCell-root {
    color: #315CCB !important;
    font-family: InterBold !important;
    text-transform: capitalize !important;
    font-size: 13px !important;
}

tbody .MuiTableCell-root {
    color: #000 !important;
    font-family: InterMedium !important;
    font-size: 12px !important;
}

::-webkit-scrollbar {
    height: 5px;
    width: 7px
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #aaaaaa;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #aaaaaa;
}

.tableContainer {
    max-height: calc(100vh - 100px) !important;
}

.table tr th {
    background-color: #D8F0D4 !important;
}
