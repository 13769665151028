.borderBottomLine {
    border-bottom: 1px solid #DDD;
}

.dataView {
    width: 20% !important;
}

.btnView {
    cursor: pointer !important;
}

.errorText {
    color: red !important;
    font-weight: bold !important;
    font-size: 13px !important;
}

.descView {
    border: 1px solid #aaa !important;
    -webkit-box-shadow: 0 2px 5px #aaa !important;
    -moz-box-shadow: 0 2px 5px #aaa !important;
    box-shadow: 0 2px 5px #aaa !important;
}

.descTitleText {
    color: #EE2525 !important;
    font-family: InterBold !important;
    font-size: 14px !important;
}

.descText {
    color: #000 !important;
    font-family: InterMedium !important;
    font-size: 13px !important;
}

.descTitleItalicText {
    color: #EE2525 !important;
    font-family: InterItalicBold !important;
    font-size: 14px !important;
}

.descItalicText {
    color: #000 !important;
    font-family: InterItalic !important;
    font-size: 13px !important;
}

.labelText {
    color: #315CCB !important;
    font-weight: bold !important;
    text-transform: capitalize !important;
    font-size: 13px !important;
    font-family: InterBold !important;
}

.subLabelText {
    color: #315CCB !important;
    font-size: 11px !important;
    text-transform: capitalize !important;
    font-family: InterMedium !important;
}

.tableRow {
    border: 1px solid #aaa !important;
}
