.borderBottomLine {
    border-bottom: 1px solid #DDD;
}

.dataView {
    width: 20% !important;
}

.productPriceText {
    color: #315CCB !important;
    text-transform: capitalize !important;
    font-size: 13px !important;
    font-family: 'InterBold' !important;
}

.productPriceExwText {
    color: #000 !important;
    font-size: 12px !important;
    font-family: 'InterMedium' !important;
}

.btnView {
    cursor: pointer !important;
}

.errorText {
    color: red !important;
    font-weight: bold !important;
    font-size: 13px !important;
}

.totalCargoLabelText {
    color: red;
    font-family: InterBold !important;
    font-size: 20px !important;
}

.cargoPriceText {
    color: red;
    font-family: InterMedium !important;
    font-size: 16px !important;
}

.paymentAmtText {
    color: #000;
    font-family: InterBold !important;
    font-size: 12px !important;
}

.paymentMainDescView {
    background-color: #D8F0D4 !important;
    border: 1px solid #D8F0D4 !important;
}

.paymentMainDescText {
    color: #000;
    font-family: InterMedium !important;
    font-size: 13px !important;
}
