.mainView {
    border: 1px solid #DDDDDD;
    padding: 30px
}

.volumeTitleText {
    color: #000 !important;
    font-weight: bold !important;
    text-transform: lowercase !important;
    font-size: 13px !important;
}

/*.addToCartView {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: space-around;*/
/*    border: 1px solid #DDD;*/
/*    height: 50px;*/
/*}*/

/*.pointerView {*/
/*    cursor: pointer;*/
/*}*/

/*.plusMinusNoText {*/
/*    color: #000;*/
/*    font-family: InterBold !important;*/
/*    font-size: 20px;*/
/*}*/

/*.borderRightLine {*/
/*    font-weight: bold;*/
/*    border-right: 1px solid #DDD;*/
/*    height: 50px;*/
/*}*/

/*.borderLeftLine {*/
/*    font-weight: bold;*/
/*    border-left: 1px solid #DDD;*/
/*    height: 50px;*/
/*}*/
