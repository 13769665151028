.menuMainBox {
    border: 1px solid #DDDDDD !important;
    height: fit-content !important;
}

.menuBorderBox {
    border-bottom: 1px solid #CCCCCC !important;
}

.menuBox {
    cursor: pointer;
}

.menuBox:hover .nonSelectedMenuText {
    font-family: InterMedium !important;
    color: #80B500 !important;
}

.menuBox:hover .nonSelectedMenuIcon path {
    fill: #80B500 !important;
}

.selectedMenuBox {
    background-color: black !important;
}

.nonSelectedMenuBox {
    background-color: white !important;
}

.selectedMenuText {
    font-family: InterMedium !important;
    color: #FFFFFF !important;
    font-size: 12px;
}

.nonSelectedMenuText {
    font-family: InterMedium !important;
    color: #000000 !important;
    font-size: 12px;
}

.selectedMenuIcon path {
    fill: #FFFFFF !important;
}

.nonSelectedMenuIcon path {
    fill: #000000 !important;
}

.dashboardBox {
    background-color: #f7f5eb !important;
    padding: 19px !important;
}

.dashboardUserText {
    font-family: InterMedium !important;
    font-size: 16px !important;
}

.dashboardDescText {
    font-family: InterMedium !important;
    font-size: 16px !important;
    line-height: 1.5 !important;
}

.dashboardLogoutText {
    font-family: InterMedium !important;
    font-size: 14px !important;
    cursor: pointer;
}

.dashboardLogoutText:hover {
    font-family: InterMedium !important;
    color: #80B500 !important;
}

.userNameText {
    font-family: InterBold !important;
    font-size: 16px !important;
}
