.labelText {
    font-size: 16px !important;
    font-family: InterBold !important;
    color: #000 !important;
}

.borderView {
    border: 1px solid #aaa !important
}

.borderBottomLine {
    border-bottom: 1px solid #DDD !important;
}

.labelView {
    border: 1px solid #aaa !important;
    background-color: #D8F0D4 !important;
    min-height: 60px !important;
}

.mainLabelText {
    font-size: 20px !important;
    font-family: InterBold !important;
    color: #000 !important;
}

.cancelBorderView {
    border: 1px solid #EE2525 !important;
}

.warningText {
    font-size: 15px !important;
    font-family: InterBold !important;
    color: #EE2525 !important;
}

.warningDescText {
    font-size: 13px !important;
    font-family: InterItalic !important;
    color: #000 !important;
}

.reasonBorderView {
    border: 1px solid #aaa !important;
}

.requiredText {
    color: red !important;
    font-family: InterBold !important;
    font-size: 15px !important;
}
