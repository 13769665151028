.mainLabelText {
    color: #000 !important;
    font-family: 'InterBold' !important;
    font-size: 15px !important;
}

.labelText {
    color: #000 !important;
    font-family: 'InterMedium' !important;
    font-size: 15px !important;
}

.requiredText {
    color: red !important;
    font-family: InterBold;
    font-size: 14px !important;
}

.borderBottomLine {
    border-bottom: 1px solid #DDD !important;
}
