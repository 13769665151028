.landingTitleText {
    color: #000;
    font-family: InterBold !important;
    font-size: 27px !important;
    line-height: 30px !important;
}

.landingDescText {
    color: #000 !important;
    font-family: InterMedium !important;
    font-size: 13px !important;
}

.landingImageView {
    height: 100% !important;
    width: 100% !important;
}

.landingImageStyle {
    height: 100% !important;
    width: 100% !important;
}
