.paymentTitleText {
    color: #000 !important;
    font-family: InterBold !important;
    font-size: 18px !important;
}

.paymentDescText {
    color: #000 !important;
    font-family: InterItalic !important;
    font-size: 14px !important;
}

.allStatementText {
    color: #315CCB !important;
    font-family: InterMedium !important;
    font-size: 16px !important;
}

.borderBottomLine {
    border: 1px solid #ccc !important;
}
