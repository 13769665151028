.mainLabelText {
    color: #000 !important;
    font-family: 'InterBold' !important;
    font-size: 15px !important;
}

.labelText {
    color: #000 !important;
    font-weight: bold !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
}

.viewText {
    cursor: pointer !important;
    color: #000 !important;
    font-size: 13px !important;
}
