.yourCartText {
    color: #000 !important;
    font-family: InterBold !important;
    font-size: 19px !important;
}

.yourCartDescText {
    color: #000 !important;
    font-family: InterMedium !important;
    font-size: 14px !important;
}

.borderBottomLine {
    border-bottom: 1px solid #aaa !important;
}

.descView {
    -webkit-box-shadow: 0 2px 5px #aaa !important;
    -moz-box-shadow: 0 2px 5px #aaa !important;
    box-shadow: 0 2px 5px #aaa !important;
}

.descTitleItalicText {
    color: #FF5B00 !important;
    font-family: InterItalicBold !important;
    font-size: 14px !important;
}

.descItalicText {
    color: #000 !important;
    font-family: InterItalic !important;
    font-size: 13px !important;
}
