.addToCartView {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border: 1px solid #DDD;
    height: 50px;
}

.pointerView {
    cursor: pointer;
}

.plusMinusNoText {
    color: #000;
    font-family: InterBold !important;
    font-size: 20px;
}

.borderRightLine {
    font-weight: bold;
    border-right: 1px solid #DDD;
    height: 50px;
}

.borderLeftLine {
    font-weight: bold;
    border-left: 1px solid #DDD;
    height: 50px;
}

.orderQuantityText {
    color: #000;
    font-family: InterMedium !important;
    font-size: 12px !important;
}

.moqErrorText {
    color: red;
    font-family: InterBold !important;
    font-size: 12px !important;
}
