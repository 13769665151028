.labelText {
    color: #000 !important;
    font-size: 15px !important;
    margin-bottom: 6px !important;
}

.requiredText {
    color: red !important;
    font-family: InterBold !important;
    font-size: 14px !important;
}

.errorBorderStyle {
    /*border: 1px solid red !important;*/
}

.borderStyle {
    /*border: 1px solid #ccc !important;*/
}

.iconView {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.disableIconView {
    pointer-events: none;
}

.errorView {
}

.errorText {
    color: red !important;
    font-size: 12px !important;
}
