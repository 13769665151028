.borderBottomLine {
    border-bottom: 1px solid #DDD;
}

.dataView {
    width: 20% !important;
}

.tableRow {
    border-bottom: 1px solid #aaa !important;
}

.productPriceText {
    color: #315CCB !important;
    text-transform: capitalize !important;
    font-size: 13px !important;
    font-family: 'InterBold' !important;
}

.productPriceExwText {
    color: #000 !important;
    font-size: 12px !important;
    font-family: 'InterMedium' !important;
}
