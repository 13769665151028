.mainView {
    border: 1px solid #DDDDDD !important;
    padding: 30px !important;
}

.labelText {
    color: #000 !important;
    font-family: InterRegular !important;
    font-size: 14px !important
}

.descText {
    color: red !important;
    font-family: InterRegular !important;
    font-size: 11px !important;
}

.paymentView {
    cursor: pointer !important;
}
